import React, { useEffect, useState } from "react";
import "./Dashboard.css";
// These imports load individual services into the firebase namespace.
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../reducers";
import { UserData } from "../model/user";
import { setUserAction, setUserDataAction } from "../loginReducer";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getInviteLinkToken, getLoginInfo } from "../services/adminService";
import { Link } from "react-router-dom";
import LogoutWidget from "../logout/LogoutWidget";
import RoundWidget from "./RoundWidget";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import { PairValue } from "../layout/Pair";
import { queryForOrganisationStatistics } from "../firebase/organisationStatistics";
import { Scout } from "../organisation/model/scout";
import StreamIcon from "../icons/StreamIcon";
import { ColumnCentered } from "../layout/Column";
import styled from "styled-components";
import { WideSection } from "../layout/WideSection";
import LoginByEmail from "../login/LoginByEmail";
import LoadingAnimation from "../animation/LoadingAnimation";
import GunshotStatistics from "./GunshotStatistics";
import UserSearch, { StyledListItem } from "./UserSearch";
import { FlexEndRow, FlexStartRow, SpaceBetweenRow } from "../layout/Row";
import ActionButton from "../button/ActionButton";

export const COMMANDER_URL = "https://commander.triangula.no";

export const goToAdminCommander = (userId: string, jwtToken: string) => {
  goToUrl(userId, jwtToken, "https://triangula-admin.firebaseapp.com");
};

export const goToCommander = (userId: string, jwtToken: string) => {
  goToUrl(userId, jwtToken, COMMANDER_URL);
};

const goToUrl = (userId: string, jwtToken: string, url: string) => {
  getLoginInfo(userId, jwtToken)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;
        const win = window.open(`${url}?token=${data.jwtToken}`, "_self");
        // @ts-ignore
        win.focus();
      } else {
        alert("Couldn't get login credentials");
      }
    })
    .catch((response) => alert("Couldn't get login credentials"));
};

export const getInviteLink = (
  userId: string,
  jwtToken: string,
  url: string,
): Promise<string> => {
  return getInviteLinkToken(userId, jwtToken).then((response) => {
    if (response.status === 200) {
      return `${COMMANDER_URL}?token=${response.data.jwtToken}`;
    } else {
      throw Error("Could not get token for invite link");
    }
  });
};

const LiveWidgetContent = styled.div`
  width: 3rem;
  margin-top: 0.4rem;
  animation: blinker 3s linear infinite;
  p {
    color: #ef4444;
    font-weight: bold;
    font-size: 0.6rem;
    margin: -0.35rem 0 0 0;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const IsLiveWidget = ({ activeScouts }: { activeScouts: number }) => {
  return (
    <LiveWidgetContent>
      {activeScouts ? (
        <ColumnCentered>
          <StreamIcon width={25} height={25} backgroundColor={"#EF4444"} />
          <p>
            {activeScouts} scout{activeScouts === 1 ? "" : "s"}
          </p>
        </ColumnCentered>
      ) : (
        <></>
      )}
    </LiveWidgetContent>
  );
};

export const CustomLink = ({
  username,
  uid,
  jwtToken,
  organisationId,
  emailNotExisting,
}: {
  username: string;
  uid: string;
  jwtToken: string;
  organisationId?: string;
  emailNotExisting?: boolean;
}) => {
  const [copied, setCopied] = useState(false);

  const getInviteLinkAndCopy = () => {
    getInviteLink(uid, jwtToken, "https://commander.triangula.no")
      .then((url) => {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          })
          .catch(() => {
            alert(
              `Copy to clipboard not supported by your device. When clicking OK, you'll be sent to the invite link, then copy url from address bar`,
            );
            const win = window.open(`${url}`, "_self");
            // @ts-ignore
            win.focus();
          });
      })
      .catch((response) => alert("Could not get token for invite link"));
  };

  return (
    <StyledListItem>
      <SpaceBetweenRow>
        <FlexStartRow>
          <div className="GunshotStatistics-label">{username}</div>
        </FlexStartRow>
        <FlexEndRow style={{ marginLeft: "1rem" }}>
          <ActionButton
            style={{ margin: "0.25rem" }}
            text={"ADMIN"}
            onClick={() => goToAdminCommander(uid, jwtToken)}
          />
          <ActionButton
            style={{ margin: "0.25rem" }}
            text={"PUBLIC"}
            onClick={() => goToCommander(uid, jwtToken)}
          />
          <ActionButton
            style={{ margin: "0.25rem" }}
            text={copied ? "Copied!" : "INVITE"}
            onClick={getInviteLinkAndCopy}
          />
          <Link
            className={"Dashboard-no-decoration"}
            to={{
              pathname: `/organisation/${organisationId}`,
              state: { username },
            }}
          >
            <ActionButton
              style={{ margin: "0.25rem" }}
              text={copied ? "Copied!" : "DASHBOARD"}
              onClick={() => {}}
            />
          </Link>
        </FlexEndRow>
      </SpaceBetweenRow>
    </StyledListItem>
  );
};

export const ActiveScouts = ({
  organisationId,
}: {
  organisationId: string;
}) => {
  const [activeScouts, setActiveScouts] = useState<Scout[]>([]);
  useEffect(() => {
    if (organisationId) {
      queryForOrganisationStatistics(organisationId).then((scouts) => {
        setActiveScouts(scouts);
      });
    }
  }, [organisationId]);
  return <IsLiveWidget activeScouts={activeScouts.length} />;
};

const LoginList = styled.div`
  max-width: 40rem;
`;

const TriangulaLogin = ({ userData, user, jwtToken }: Props) => {
  return (
    <LoginList className={"Widget-item Dashboard-commander-login"}>
      {user && <h3 className={"Widget-title"}>Triangula accounts</h3>}
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          <CustomLink
            uid={user.uid}
            username={userData.email}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"AKPJWlO43FPofxhX84jPugNmwKf2"}
            organisationId={"9"}
            username={"triangulascout@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"AVlPPcW0eHb8FEPeHt4eAdLcZTp1"}
            organisationId={"18"}
            username={"triangulashowcase@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mZpPqQblFWXeiu5cIpjM4ncLjsH2"}
            organisationId={"7"}
            username={"sales@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mLr09uUG7ZaJxJ1DgaBoUouCu0K2"}
            organisationId={"19"}
            username={"kit1@triangula.no"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mMAUd5sE5RVd4Lkq40e0DTwLnuW2"}
            organisationId={"20"}
            username={"kit2@triangula.no"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"4AHZfMDBfzYvywjdzBrbi7R3pmF3"}
            organisationId={"12"}
            username={"triangulademo@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"zuZGT6LgSqh4swwXfJtHE9db1J23"}
            organisationId={"52"}
            username={"ios@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"2jXahmQYbhM7f662tKIozj4OkIz2"}
            organisationId={"75"}
            username={"triangula.test@icloud.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"DxmMLDS48pQLBWdcvbTs3EsoCyv1"}
            organisationId={"84"}
            username={"festningen@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"kYBSBEgrWNYwH9V3FIx5M3ddERx2"}
            organisationId={"85"}
            username={"rena@triangula.com"}
            jwtToken={jwtToken}
          />
        </>
      ) : (
        <></>
      )}
    </LoginList>
  );
};

const LoginToAnyAccount = ({ userData, user, jwtToken }: Props) => {
  return;
};

const ExternalLogin = ({ userData, user, jwtToken }: Props) => {
  return (
    <LoginList className={"Widget-item Dashboard-commander-login"}>
      {user && <h3 className={"Widget-title"}>External accounts</h3>}
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          <CustomLink
            uid={"4SiChccIZ8dKnFDbYbQ4OZaeetw1"}
            organisationId={"17"}
            username={"triangulanammo@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"VVCvVjRUr3hMvdZx1t4Jh87UxGK2"}
            organisationId={"13"}
            username={"triangulakit1@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"rnJzyIHDDQcEwDjQiY7ne5KQ0JO2"}
            organisationId={"1"}
            username={"triangulakit2@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"s6es1yIJWBa4VzDQXbTSc8Kix1P2"}
            organisationId={"21"}
            username={"triangulakit3@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"TQot1DIeFeMw4T93PEPtsH7wRlm1"}
            organisationId={"22"}
            username={"triangulakit4@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"98LtYs1vMhd2PWq6OtYgxGx8RgL2"}
            organisationId={"23"}
            username={"triangulakit5@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />

          <CustomLink
            uid={"An04B9S7OeMcfcBlTpoKCSpC7Gz2"}
            organisationId={"25"}
            username={"triangulakit6@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"3Pc9CCQytaSOc5fdjgOo3Mw1cyP2"}
            organisationId={"26"}
            username={"triangulakit7@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"ooP2hZAFyPMfBjrRxYJYKgb014f2"}
            organisationId={"27"}
            username={"triangulakit8@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"uvNDO9AzcVXjhdbHpOhjiT3Naxv1"}
            organisationId={"28"}
            username={"triangulakit9@gmail.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
          <CustomLink
            uid={"qXnVRz9liigcmxcWTfkokXervyv2"}
            organisationId={"29"}
            username={"triangulakit10@triangula.com"}
            jwtToken={jwtToken}
            emailNotExisting={true}
          />
        </>
      ) : (
        <></>
      )}
      <PairValue>* Gmail account not registered at Google</PairValue>
    </LoginList>
  );
};

export const SubSubSection = styled.div`
  display: flex;
  flex-grow: 1;
`;

const LoginsSection = styled.div`
  display: flex;
  flex-grow: 3;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

const SmallOperationBoxSection = styled(SubSubSection)`
  flex-direction: column;
`;

const IncidentLiveView = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && (
            <h3 className={"Widget-title"}>Potential incident live view</h3>
          )}
          <p className={"Widget-description"}>
            Live view of all potential incidents that occurs in the system
            across all organisations and devices
          </p>
          <Link className={"Dashboard-no-decoration"} to={"/incidentPlayer"}>
            <p className={"Dashboard-clickable-text"}>Go to live view</p>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const AppVersionView = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && <h3 className={"Widget-title"}>App versions</h3>}
          <p className={"Widget-description"}>
            Overview of app versions in use
          </p>
          <Link className={"Dashboard-no-decoration"} to={"/scoutVersions"}>
            <p className={"Dashboard-clickable-text"}>
              Go to app version overview
            </p>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const ConfigStoreView = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && <h3 className={"Widget-title"}>Config store</h3>}
          <p className={"Widget-description"}>Edit properties</p>
          <Link className={"Dashboard-no-decoration"} to={"/configStore"}>
            <p className={"Dashboard-clickable-text"}>Go to view</p>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const MonitorView = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && <h3 className={"Widget-title"}>Monitor</h3>}
          <p className={"Widget-description"}>Monitor the system</p>
          <Link className={"Dashboard-no-decoration"} to={"/monitor"}>
            <p className={"Dashboard-clickable-text"}>Go to view</p>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const SystemSettingsView = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && <h3 className={"Widget-title"}>System settings</h3>}
          <p className={"Widget-description"}>
            View where you control the Triangulas global system settings
          </p>
          <Link className={"Dashboard-no-decoration"} to={"/systemSettings"}>
            <p className={"Dashboard-clickable-text"}>Go to view</p>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const LoginByEmailWidget = ({ userData, user, jwtToken }: Props) => {
  return (
    <div className={"Widget-small-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          <LoginByEmail jwtToken={jwtToken} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const LearningView = () => {
  return (
    <div
      style={{ flexGrow: 0, height: "130px" }}
      className={"Widget-small-item"}
    >
      <h3 className={"Widget-title"}>Learning</h3>
      <p className={"Widget-description"}>
        Learning is where we test our classifiers
      </p>
      <a
        href={"https://triangula-learning.web.app/#/"}
        className={"Dashboard-clickable-text Dashboard-no-decoration"}
      >
        Go to Learning
      </a>
    </div>
  );
};

const LabelAnalyzerView = ({ jwtToken }: Props) => {
  return (
    <div
      style={{ flexGrow: 0, height: "130px" }}
      className={"Widget-small-item"}
    >
      <h3 className={"Widget-title"}>Dataset Label Verification</h3>
      <p className={"Widget-description"}>
        Verifying the labels of our dataset
      </p>
      <a
        href={`https://dataset-label-verification.triangula.com/?token=${jwtToken}`}
        className={"Dashboard-clickable-text Dashboard-no-decoration"}
      >
        Go to view
      </a>
    </div>
  );
};

const OperationWidgetsWrapper = styled.div`
  display: flex;
  @media (max-width: 1500px) {
    flex-wrap: wrap;
  }
`;

const OperationWidgets = (props: Props) => {
  return (
    <WideSection>
      <div>
        <h3 className={"Widget-section-title"}>Operations</h3>
        <OperationWidgetsWrapper>
          <UserSearch jwtToken={props.jwtToken!} />

          <SmallOperationBoxSection>
            <LoginByEmailWidget {...props} />
            <IncidentLiveView {...props} />
            <SystemSettingsView {...props} />
            <AppVersionView {...props} />
          </SmallOperationBoxSection>
        </OperationWidgetsWrapper>
      </div>
    </WideSection>
  );
};

const DataProcessingWidgets = (props: Props) => {
  return (
    <WideSection>
      <div className={"Widget-content"}>
        <h3 className={"Widget-section-title"}>Data processing</h3>
        <div className="Widget-items">
          <RoundWidget />
          <SmallOperationBoxSection>
            <ConfigStoreView {...props} />
            <MonitorView {...props} />
            <LearningView />
            <LabelAnalyzerView {...props} />
          </SmallOperationBoxSection>
        </div>
      </div>
    </WideSection>
  );
};

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      loadingUserData: true,
      unregisterAuthObserver: undefined,
    };
  }

  render() {
    return (
      <div className="Dashboard">
        <div className={"Dashboard-header"}>
          <SmallLogoWithDashboard fontSizeRem={1.625} />
          <LogoutWidget {...this.props} />
        </div>
        {this.props.userData && this.props.jwtToken ? (
          <>
            <GunshotStatistics jwtToken={this.props.jwtToken} />
            <OperationWidgets {...this.props} />
            <DataProcessingWidgets {...this.props} />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2rem",
            }}
          >
            <LoadingAnimation height={30} width={30} />
          </div>
        )}
      </div>
    );
  }
}

interface State {
  loading: boolean;
  loadingUserData: boolean;
  unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch,
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
