import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { Incident } from "../model/incident";
import { OrganisationDetails } from "../player/incidentReducer";
import {
  BasePosition,
  Ammunition,
  OutlierData,
  GunshotObservation,
} from "../model/rounds";
import { CalculatedTiming } from "../model/timing";
import { TimingScores, DetectionSettings } from "../firebase/configuration";
import { createAudioObjectPath } from "../util";
import { GunshotStatisticsForTimeRanges } from "../dashboard/GunshotStatistics";
import { AppVersion } from "../player/ScoutVersionPage";

const getAxiosConfig = (jwtToken: string): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
};

const getAxiosBlobConfig = (jwtToken: string): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
    responseType: "blob",
  };
};

export const getLoginInfo = (userId: string, jwtToken: string) => {
  return axios.post(
    `https://api.triangula.no/backoffice/login-as-user`,
    { userId: userId },
    getAxiosConfig(jwtToken),
  );
};

export const getLoginInfoByEmail = (email: string, jwtToken: string) => {
  return axios.post(
    `https://api.triangula.no/backoffice/login-by-email`,
    { email },
    getAxiosConfig(jwtToken),
  );
};

export const getInviteLinkToken = (userId: string, jwtToken: string) => {
  return axios.post(
    `https://api.triangula.no/backoffice/login/createInviteJwt`,
    { userId: userId },
    getAxiosConfig(jwtToken),
  );
};

export const updateTimingScores = (
  jwtToken: string,
  scores: TimingScores,
  onSuccess: () => void,
) => {
  return axios
    .post(
      `https://api.triangula.no/backoffice/configuration/updateTimingScores`,
      { scores },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        onSuccess();
      }
    });
};

export const updateDetection = (
  jwtToken: string,
  settings: DetectionSettings,
  onSuccess: () => void,
) => {
  return axios
    .post(
      `https://api.triangula.no/backoffice/configuration/updateDetection`,
      settings,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        onSuccess();
      }
    });
};

export const updateTemperature = (
  jwtToken: string,
  temperature: number,
  onSuccess: () => void,
) => {
  return axios
    .post(
      `https://api.triangula.no/backoffice/configuration/temperature`,
      { temperature: temperature },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        onSuccess();
      }
    });
};

export interface OrganisationInfo {
  name: string;
  accessCode: string;
  organisationId: string;
  mode: string;
  units: string;
  sales: boolean;
}

export const getOrganisationInfo = (
  jwtToken: string,
  organisationId: string,
  onSuccess: (_: OrganisationInfo) => void,
) => {
  return axios
    .get(
      `https://api.triangula.no/backoffice/organisations/${organisationId}/summary`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        onSuccess({ ...response.data, organisationId } as OrganisationInfo);
      }
    });
};

export const getAppVersion = (
  jwtToken: string,
  organisationId: string | undefined,
  onSuccess: (_: AppVersion[]) => void,
) => {
  // onSuccess(
  //   [{"appVersion": "0.33.55", "count":3},
  //   {"appVersion": "0.33.56", "count":4},
  //   {"appVersion": "1.5(228)", "count":4}
  // ].map((v: any) => ({
  //     ...v,
  //     platform: v.appVersion.indexOf("(") === -1 ? "Android" : "Apple",
  //   })) as AppVersion[]
  // )
  // return
  axios
    .get(
      `https://api.triangula.no/backoffice/scout/versions${organisationId ? `?organisationId=${organisationId}` : ""}`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        onSuccess(
          response.data.map((v: any) => ({
            ...v,
            platform: v.appVersion.indexOf("(") === -1 ? "Android" : "Apple",
          })) as AppVersion[],
        );
      }
    });
};

export enum SearchEntity {
  organisation = "organisation",
  user = "user",
}

export interface UserSearchResult {
  entity: SearchEntity;
  id: string;
  name: string;
  ownerId: string;
}

export const searchForUserAndOrganisation = (
  jwtToken: string,
  query: String,
): Promise<UserSearchResult[]> => {
  return axios
    .get(
      `https://api.triangula.no/user/search?q=${query}`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        console.log("Result: ", response.data);

        return response.data.result as UserSearchResult[];
      } else {
        return [];
      }
    });
};

export const getGunshotStatistics = (
  jwtToken: string,
): Promise<GunshotStatisticsForTimeRanges[]> => {
  return axios
    .get(
      `https://api.triangula.no/backoffice/organisationStatistics`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data as GunshotStatisticsForTimeRanges[];
      } else {
        throw new Error("Failed to get gunshot statistics");
      }
    });
};

export interface ClassificationScore {
  name: string;
  value: number;
}

export interface DistancePredictions {
  ranges: DistancePrediction[];
}

export interface DistancePrediction {
  from: number;
  to: number;
  prediction: number;
}

export interface AudioFile {
  "bucket-name": string;
  filename: string;
}

export const getClassifications = (
  file: AudioFile,
  jwtToken: string,
  callback: (image: ClassificationScore[]) => void,
) => {
  axios
    .post(
      `https://api.triangula.no/classifiers/gunshot`,
      { "audio-file": file },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback([
          {
            value: response.data.result.value,
            name: response.data.result["classifier-name"],
          },
        ] as ClassificationScore[]);
      }
    });
};

export const getOrganisationDetails = (
  organisationId: string,
  jwtToken: string,
  callback: (_: OrganisationDetails) => void,
) => {
  axios
    .get(
      `https://api.triangula.no/backoffice/organisations/${organisationId}`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as OrganisationDetails);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const getPeaks = (
  file: AudioFile,
  gunshot: GunshotObservation,
  jwtToken: string,
  callback: (
    peaks: { [id: string]: number[] },
    classifications: ClassificationScore[],
  ) => void,
  secondAttempt: boolean = false,
) => {
  axios
    .post(
      `https://api.triangula.no/backoffice/audio/peaks`,
      {
        roundName: gunshot.round.name,
        deviceId: gunshot.device.name,
        audioTut: gunshot.audio.tut,
        audioFile: { bucketName: file["bucket-name"], filename: file.filename },
      },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback(
          response.data.peaks as { [id: string]: number[] },
          (response.data.classifications?.map((c) => ({
            name: c.name,
            value: c.score,
          })) as ClassificationScore[]) ?? [],
        );
        // tslint:disable-next-line: no-empty
      } else {
        if (!secondAttempt) {
          setTimeout(
            () => getPeaks(file, gunshot, jwtToken, callback, true),
            15000,
          );
        }
      }
    });
};

export interface TimingCategories {
  weaponType: { [id: string]: number };
  sonicBoom: { [id: string]: number };
  distance: { [id: string]: number };
  angle: { [id: string]: number };
  weaponAndCaliber: { [id: string]: number };
}

export const getTimingCategories = (
  gunshot: GunshotObservation,
  jwtToken: string,
  sampleNumber: number,
  callback: (_: TimingCategories) => void,
) => {
  axios
    .post(
      `https://api.triangula.no/backoffice/classify/timing/categories`,
      {
        roundName: gunshot.round.name,
        deviceId: gunshot.device.name,
        audioTut: gunshot.audio.tut,
        sampleNumber: sampleNumber,
        shotNumber: gunshot.shot.index,
      },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200 && Object.keys(response.data).length) {
        callback(response.data as TimingCategories);
        // tslint:disable-next-line: no-empty
      }
    });
};

export const getPeakCategoryGraphData = (
  file: AudioFile,
  jwtToken: string,
  secondAttempt: boolean = false,
) => {
  return axios
    .post(
      `https://api.triangula.no/classifiers/weapon/graph`,
      { audioFile: file },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data as { [id: string]: number[] };
      } else {
        throw new Error(`ERROR: got ${response.status}`);
      }
    });
};

export const getTimingPredictionsGraphData = (
  file: AudioFile,
  jwtToken: string,
) => {
  return axios
    .post(
      `https://api.triangula.no/classifiers/timing/predictions`,
      { "audio-file": file },
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200 && Object.keys(response.data).length > 0) {
        return { predictions: response.data["predictions"][0] } as {
          [id: string]: number[];
        };
      } else {
        throw new Error(`ERROR: got ${response.status}`);
      }
    });
};

export const getOutlierData = (
  jwtToken: string,
  roundName: string,
  device: string,
) => {
  return axios
    .get(
      `https://api.triangula.no/round/${roundName}/${device}/outliers`.replace(
        "+",
        "%2B",
      ),
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data as OutlierData;
      } else if (response.status === 204) {
        throw new Error(`No outlier data found for device`);
      } else {
        throw new Error(`ERROR: got ${response.status}`);
      }
    });
};

export const getTimings = (
  speedOfSound: number,
  listeningLocation: BasePosition,
  impactLocation: BasePosition,
  shootingLocation: BasePosition,
  ammunition: Ammunition | undefined,
  jwtToken: string,
  callback: (timings: CalculatedTiming) => void,
) => {
  const bc = ammunition && (ammunition.bc || ammunition.bc_doc);
  const v0 = ammunition && (ammunition.v0_calculated || ammunition.v0 || ammunition.v0_doc);

  if (!ammunition || !bc || bc === null || !v0 || v0 === null) {
    return;
  }

  const request = {
    speedOfSound: speedOfSound,
    shooterLocation: {latitude: shootingLocation.lat, longitude: shootingLocation.lon},
    observationLocation: {latitude: listeningLocation.lat, longitude: listeningLocation.lon},
    impactLocation: {latitude: impactLocation.lat, longitude: impactLocation.lon},
    ammunition: {
      v0,
      bc,
      // diameter in meters
      caliber:
        (ammunition.unit === "mm" ? ammunition.size : ammunition.size * 25.4)/1000,
        // weight in kg
      weight: ammunition.weight / 1000,
    },
  };
  axios
    .post(
      `https://api.triangula.no/round/calculateSonicBoom`,
      request,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as CalculatedTiming);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const suggestV0Timings = (
  speedOfSound: number,
  listeningLocation: BasePosition,
  impactLocation: BasePosition,
  shootingLocation: BasePosition,
  ammunition: Ammunition | undefined,
  timeDifferenceBetweenSonicBoomAndMuzzleBlastSeconds:number,
  jwtToken: string,
): Promise<number | undefined> => {
  const bc = ammunition && (ammunition.bc || ammunition.bc_doc);
  const v0 = ammunition && (ammunition.v0 || ammunition.v0_doc);

  if (!ammunition || !bc || bc === null || !v0 || v0 === null) {
    return Promise.resolve(undefined);
  }

  const request = {
    speedOfSound: speedOfSound,
    shooterLocation: {latitude: shootingLocation.lat, longitude: shootingLocation.lon},
    observationLocation: {latitude: listeningLocation.lat, longitude: listeningLocation.lon},
    impactLocation: {latitude: impactLocation.lat, longitude: impactLocation.lon},
    timeDifferenceBetweenSonicBoomAndMuzzleBlast: timeDifferenceBetweenSonicBoomAndMuzzleBlastSeconds,
    ammunition: {
      v0,
      bc,
      // diameter in meters
      caliber:
        (ammunition.unit === "mm" ? ammunition.size : ammunition.size * 25.4)/1000,
        // weight in kg
      weight: ammunition.weight / 1000,
    },
  };
  return axios
    .post(
      `https://api.triangula.no/round/calculateV0`,
      request,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data.v0 as number | undefined;
        // tslint:disable-next-line: no-empty
      } else {
        alert("Failed to calculate V0");
        return undefined
      }
    });
};

export const persistV0 = (
  v0: number,
  ammunitionId: string,
  weaponId: string,
  jwtToken: string,
): Promise<number | undefined> => {


  if (!ammunitionId || !weaponId) {
    alert("Ammunition or weapon not found");
    return Promise.resolve(undefined);
  }

  const request = {
    v0: v0,
    ammunitionId: ammunitionId,
    weaponId: weaponId,
  };
  return axios
    .post(
      `https://api.triangula.no/round/storeCalculatedV0`,
      request,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data.storedV0 as number;
        // tslint:disable-next-line: no-empty
      } else {
        alert("Failed to store V0");
        return undefined
      }
    });
};

export const getDirectionPrediction = (
  devices: GunshotObservation[],
  jwtToken: string,
  callback: (timings: number[]) => void,
) => {
  const request = {
    devices: devices.map((d) => ({
      audioFile: {
        "bucket-name": d.audio.file_location.bucket,
        filename: createAudioObjectPath(d),
      },
      sampleNumber: d.manual!.sample_number,
      latitude: d.device.position.lat,
      longitude: d.device.position.lon,
    })),
    gunshot: {
      latitude: devices[0].device.position.master.lat,
      longitude: devices[0].device.position.master.lon,
    },
  };
  axios
    .post(
      `https://api.triangula.no/classifiers/direction`,
      request,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200 && response.data.length > 0) {
        callback(response.data as number[]);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export interface WeaponType {
  name: string;
  probability: number;
}

export interface Silencer {
  attached: boolean;
  probability: number;
}

export interface Caliber {
  size: number;
  probability: number;
}
export interface WeaponDetails {
  type: WeaponType;
  silencer: Silencer;
  caliber: Caliber;
}

export const getWeaponDetailsPrediction = (
  devices: GunshotObservation[],
  jwtToken: string,
  callback: (details: WeaponDetails) => void,
) => {
  const request = {
    incidents: devices.map((d) => ({
      audioFile: {
        "bucket-name": d.audio.file_location.bucket,
        filename: createAudioObjectPath(d),
      },
      distance: d.device.position.distance,
      sampleNumber: d.selectedSample
        ? d.selectedSample.sample_number
        : d.manual!.sample_number,
    })),
  };
  axios
    .post(
      `https://api.triangula.no/classifiers/weapon`,
      request,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      console.log("WEAPON_DETAILS", 3);
      if (response.status === 200) {
        console.log(
          "WEAPON_DETAILS",
          4,
          response.data.prediction as WeaponDetails,
        );
        callback(response.data.prediction as WeaponDetails);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const downloadFile = (
  incident: Incident,
  url: string,
  jwtToken: string,
) => {
  axios
    .get(url.replace("+", "%2B"), getAxiosBlobConfig(jwtToken))
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${incident.id}.wav`);
      document.body.appendChild(link);
      link.click();
    });
};

export const getSpectrogram = (
  deviceId: string,
  incidentId: string,
  jwtToken: string,
  callback: (image: string) => void,
) => {
  axios
    .post(
      `https://api.triangula.no/audio/spectrogram`,
      { deviceId, incidentId },
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
        responseType: "arraybuffer",
      },
    )
    .then((response) => {
      if (response.status === 200) {
        callback(Buffer.from(response.data, "binary").toString("base64"));
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

interface MarkAsGunshotRequest {
  deviceId: string;
  incidentId: string;
}

export const markAsNotGunshot = (
  deviceId: string,
  incidentId: string,
  jwtToken: string,
): AxiosPromise => {
  const request: MarkAsGunshotRequest = { deviceId, incidentId };
  return axios.post(
    `https://api.triangula.no/audio/not-gunshot`,
    request,
    getAxiosConfig(jwtToken),
  );
};

export const getConfigStore = (
  jwtToken: string,
  filename: string,
  callback: (content: any) => void,
) => {
  return axios
    .get(
      `https://api.triangula.no/backoffice/config-store/${filename}`.replaceAll(
        "#",
        "%23",
      ),
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const getConfigStoreSuggestions = (
  jwtToken: string,
  callback: (filenames: string[]) => void,
) => {
  return axios
    .get(
      `https://api.triangula.no/backoffice/config-store`,
      getAxiosConfig(jwtToken),
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.names);
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const updateConfigStore = (
  jwtToken: string,
  filename: string,
  content: string,
  callback: () => void,
) => {
  try {
    return axios
      .post(
        `https://api.triangula.no/backoffice/config-store/${filename}`.replaceAll(
          "#",
          "%23",
        ),
        JSON.parse(content),
        getAxiosConfig(jwtToken),
      )
      .then((response) => {
        if (response.status === 200) {
          callback();
        } else {
          callback();
          alert(`[${response.status}]: ${response.data}`);
        }
      })
      .catch((err) => {
        callback();
        alert(err);
      });
  } catch (e) {
    alert(e);
  }
};
